import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";
import React from "react";

interface ICloseButton {
  handleOnClose: (
    event: {},
    reason?: "backdropClick" | "escapeKeyDown"
  ) => void;
  disabled: boolean;
}

const CloseButton: React.FC<ICloseButton> = ({
  handleOnClose,
  disabled = false,
}): JSX.Element => {
  return (
    <IconButton
      aria-label="close"
      sx={{
        position: "absolute",
        top: 0,
        right: 0,
      }}
      onClick={handleOnClose}
      disabled={disabled}
    >
      <CloseIcon />
    </IconButton>
  );
};

export default CloseButton;
