import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

const defaultForm = {
  email: "",
  confirmationCode: "",
  newPassword: "",
};

const initialState: any = {
  user: {},
  openDownloadModal: false,
  forgetPassword: {
    openModal: false,
    form: defaultForm,
  },
};

export const userSlice = createSlice({
  name: "userSlice",
  initialState,
  reducers: {
    setOpenDownloadModal: (state, action: PayloadAction<boolean>) => {
      state.openDownloadModal = action.payload;
    },
    setUser: (state, action: PayloadAction<any>) => {
      state.user = action.payload;
    },
    setUpdateFormEmail: (state, action: PayloadAction<string>) => {
      state.forgetPassword.form.email = action.payload;
    },
    setUpdateFormPassword: (state, action: PayloadAction<string>) => {
      state.forgetPassword.form.newPassword = action.payload;
    },
    setUpdateFormConfirmCode: (state, action: PayloadAction<string>) => {
      state.forgetPassword.form.confirmationCode = action.payload;
    },
    setOpenForgotPasswordModal: (state) => {
      state.forgetPassword.openModal = !state.forgetPassword.openModal;
      state.forgetPassword.form = defaultForm;
    },
  },
});

export const {
  setUser,
  setOpenDownloadModal,
  setOpenForgotPasswordModal,
  setUpdateFormConfirmCode,
  setUpdateFormPassword,
  setUpdateFormEmail
} = userSlice.actions;

export default userSlice.reducer;
