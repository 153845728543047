import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  ButtonProps,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Paper,
  Tab,
  TableContainer,
  Tabs,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  handleDeleteAccount,
  handleDeleteCountry,
  handleDeleteDepartment,
  handleDeleteRole,
  handleGetAccounts,
  handleGetCompanies,
  handleGetCountries,
  handleGetDepartments,
  handleGetRoles,
} from "../../api/accounts";
import { AccountTable } from "../../components/Home/AccountTable";
import AddAccountModal from "../../components/Home/AddAccountModal";
import { CompanyTable } from "../../components/Home/CompanyTable";
import UpdateCompanyModal from "../../components/Home/UpdateCompanyModal";
import UploadFileTabPanel from "../../components/Home/UploadFileTabPanel";
import { useAppDispatch, useAppSelector } from "../../store/reduxHooks";
import { getCookie, removeCookie } from "../../utils/cookie";
import { SimpleTable } from "../../components/Home/SimpleTable";
import AddCompanyModal from "../../components/Home/AddCompanyModal";
import SimpleModal from "../../components/Home/SimpleModal";

const AuthCustomButton = styled(LoadingButton)<ButtonProps>(({ theme }) => ({
  borderRadius: 5,
  fontSize: 15,
  textTransform: "none",
}));

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function tabProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const HomePage = () => {
  const user = useAppSelector((state) => state.userSlice.user);
  type DeleteHandler = (id: string) => Promise<void>;
  const [companyData, setCompanyData] = useState([]);
  const [ countries, setCountries] = useState([]);
  const [ departments, setDepartments] = useState([]);
  const [ roles, setRoles] = useState([]);
  const [userId, setUserId] = useState("");
  const [accountData, setAccountData] = useState([]);
  const [selectedRow, setSelectedRow] = useState({
    first_name: "",
    last_name: "",
    company_name: "",
    action: "",
    email: "",
    company_id: "",
    department: "",
    id: "",
  });
  const [value, setValue] = React.useState(0);
  const [selectedCompany, setSelectedCompany] = useState({
    name: "",
  });
  const [selectedCountry, setSelectedCountry] = useState({
    name: "",
    company_id: "",
  });
  
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isDoneSaving, setIsDoneSaving] = useState(false);
  const [open, setOpenAddModal] = React.useState(false);
  const [openCompany, setOpenCompanyModal] = useState(false);
  const [openSimpleModal, setOpenSimpleModal] = useState(false);
  const [ modal, setModal] = useState<any>({});
  const [dialogConfirmation, setDialogConfirmation] = useState(false);
  const [openConfirmation, setOpenConfirmation] = React.useState(false);
  const [openUpdateCompany, setOpenUpdateCompanyModal] = useState(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [currentDelete, setCurrentDelete] = useState<{ handler: DeleteHandler; id: string } | null>(null);

  const handleUpdateCompanyClick = (rowData: any) => {
    setSelectedRow({
      first_name: rowData.first_name,
      last_name: rowData.last_name,
      company_name: rowData.name,
      department: rowData.department,
      action: "update",
      email: "",
      company_id: "",
      id: rowData.id,
    });
    setOpenUpdateCompanyModal(true);
  };
  const handleUpdateCountryClick = (rowData: any) => {
    setSelectedCountry({
      name: rowData.name,
      company_id: rowData.company_id,
    });
    setModal({ type: 'country', kind: 'update' });
    setOpenSimpleModal(true);
  }

  const handleClickOpenConfirmation = () => {
    setOpenConfirmation(true);
  };

  const handleDeleteConfirmation = () => {
    setDialogConfirmation(true);
  };

  const handleCancelConfirmation = () => {
    setOpenConfirmation(false);
  };

  function handleDeleteEntity(deleteHandler: DeleteHandler, id: string) {
    setUserId(id);
    setCurrentDelete({ handler: deleteHandler, id: id });
    handleClickOpenConfirmation();
  }

  useEffect(() => {
    if (dialogConfirmation && currentDelete) {
      const fetchData = async () => {
        try {
          setIsLoading(true);
          await currentDelete.handler(currentDelete.id);
          setIsDoneSaving(prev => !prev);
          setOpenAddModal(false);
        } catch (err) {
          setIsLoading(false);
          alert("Error while deleting");
          console.log(err);
        } finally {
          setIsLoading(false);
          setDialogConfirmation(false);
          setOpenConfirmation(false);
        }
      };
  
      fetchData();
    }
  }, [dialogConfirmation, currentDelete]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  useEffect(() => {
    const fetchAccount = async () => {
      try {
        setIsLoadingData(true);
        const [companies, accounts, countries, departments, roles] = await Promise.all([
          handleGetCompanies(),
          handleGetAccounts(),
          handleGetCountries(),
          handleGetDepartments(),
          handleGetRoles(),
        ]);
        setCompanyData(companies.data.data);
        setAccountData(accounts.data.data);
        setCountries(countries.data.data);
        setDepartments(departments.data.data);
        setRoles(roles.data.data);
        
        setIsLoadingData(false);
      } catch (err) {
        setIsLoadingData(false);
        console.log(err);
      }
    };
    if (user) {
      fetchAccount();
    }
  }, [isDoneSaving, user, getCookie()]);

  function handleUpdateClick(row: any) {
    setSelectedRow({
      first_name: row.first_name,
      last_name: row.last_name,
      company_name: row.name,
      department: row.department,
      action: "update",
      email: "",
      company_id: "",
      id: row.id,
    });
    setOpenAddModal(true);
  }

  function handleCreateClick(type: string) {
    switch (type) {
      case "account":
        setSelectedRow({
          first_name: "",
          last_name: "",
          company_name: "",
          action: "create",
          email: "",
          company_id: "",
          department: "",
          id: "",
        });
        setOpenAddModal(true);
        break;
      case "company":
        setSelectedCompany({
          name: "",
        });
        setOpenCompanyModal(true);
        break;
      case "country":
        setModal({ type: 'country', kind: 'create' });
        setOpenSimpleModal(true);
        break;
      case "department":
        setModal({ type: 'department', kind: 'create' });
        setOpenSimpleModal(true);
        break;
      case "role":
        setModal({ type: 'role', kind: 'create' });
        setOpenSimpleModal(true);
        break;
      default:
        break;
    }
  }

  async function handleDelete(id: string) {
    handleDeleteEntity(handleDeleteAccount, id);
  }

  async function handleDeleteDepartmentById(id: string) {
    handleDeleteEntity(handleDeleteDepartment, id);
  }

  async function handleDeleteRoleById(id: string) {
    handleDeleteEntity(handleDeleteRole, id);
  }

  async function handleDeleteCountryById(id: string) {
    handleDeleteEntity(handleDeleteCountry, id);
  }

  return (
    <Box sx={{ padding: 5 }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs value={value} onChange={handleChange} aria-label="tabs">
          <Tab label="Users" {...tabProps(0)} />
          <Tab label="Companies" {...tabProps(1)} />
          <Tab label="Countries" {...tabProps(2)} />
          <Tab label="Departaments" {...tabProps(3)} />
          <Tab label="Roles" {...tabProps(4)} />
          <Tab label="Upload file for mosaic trials" {...tabProps(5)} />
        </Tabs>
      </Box>
      {user && (
        <Typography sx={{ mt: 5 }}>
          Welcome {user.first_name} - {process.env.REACT_APP_ENVIRONMENT}
        </Typography>
      )}
      <TabPanel value={value} index={0}>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box>
            <Button
              variant="contained"
              sx={{ marginTop: 4, marginBottom: 4 }}
              onClick={() => handleCreateClick("account")}
            >
              Add account
            </Button>
          </Box>

          <Button
            sx={{ marginTop: 4, marginBottom: 4 }}
            onClick={() => {
              removeCookie();
              navigate("/");
            }}
          >
            Log out
          </Button>
        </Box>

        <TableContainer component={Paper} sx={{ maxHeight: 600 }}>
          {isLoadingData ? (
            <Box sx={{ textAlign: "center", marginTop: 5, marginBottom: 5 }}>
              <CircularProgress />
            </Box>
          ) : (
            <AccountTable
              isLoading={isLoading}
              handleUpdateClick={handleUpdateClick}
              accountData={accountData}
              AuthCustomButton={AuthCustomButton}
              handleDelete={handleDelete}
            />
          )}
        </TableContainer>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Button
          variant="contained"
          sx={{ marginTop: 4, marginBottom: 4 }}
          onClick={() => handleCreateClick("company")}
        >
          Add new company
        </Button>
        <TableContainer component={Paper} sx={{ maxHeight: 600 }}>
          {isLoadingData ? (
            <Box sx={{ textAlign: "center", marginTop: 5, marginBottom: 5 }}>
              <CircularProgress />
            </Box>
          ) : (
            <CompanyTable
              handleUpdateCompanyClick={handleUpdateCompanyClick}
              isLoading={isLoading}
              companyData={companyData}
              AuthCustomButton={AuthCustomButton}
              handleDelete={handleDelete}
            />
          )}
        </TableContainer>
      </TabPanel>
      <TabPanel value={value} index={2}>
        <Button
          variant="contained"
          sx={{ marginTop: 4, marginBottom: 4 }}
          onClick={() => handleCreateClick("country")}
        >
          Add new Country
        </Button>
        <TableContainer component={Paper} sx={{ maxHeight: 600 }}>
          {isLoadingData ? (
            <Box sx={{ textAlign: "center", marginTop: 5, marginBottom: 5 }}>
              <CircularProgress />
            </Box>
          ) : (
            <SimpleTable
              handleUpdateClick={handleUpdateCountryClick}
              isLoading={isLoading}
              tableData={countries}
              head={["Contry Name", ""]}
              AuthCustomButton={AuthCustomButton}
              handleDelete={handleDeleteCountryById}
            />
          )}
        </TableContainer>
      </TabPanel>
      <TabPanel value={value} index={3}>
        <Button
          variant="contained"
          sx={{ marginTop: 4, marginBottom: 4 }}
          onClick={() => handleCreateClick("department")}
        >
          Add new Department
        </Button>
        <TableContainer component={Paper} sx={{ maxHeight: 600 }}>
          {isLoadingData ? (
            <Box sx={{ textAlign: "center", marginTop: 5, marginBottom: 5 }}>
              <CircularProgress />
            </Box>
          ) : (
            <SimpleTable
            handleUpdateCompanyClick={handleUpdateCompanyClick}
            isLoading={isLoading}
            tableData={departments}
            head={["Department Name", ""]}
            AuthCustomButton={AuthCustomButton}
            handleDelete={handleDeleteDepartmentById}
            />
          )}
        </TableContainer>
      </TabPanel>
      <TabPanel value={value} index={4}>
        <Button
          variant="contained"
          sx={{ marginTop: 4, marginBottom: 4 }}
          onClick={() => handleCreateClick("role")}
        >
          Add new Role
        </Button>
        <TableContainer component={Paper} sx={{ maxHeight: 600 }}>
          {isLoadingData ? (
            <Box sx={{ textAlign: "center", marginTop: 5, marginBottom: 5 }}>
              <CircularProgress />
            </Box>
          ) : (
            <SimpleTable
              handleUpdateCompanyClick={handleUpdateCompanyClick}
              isLoading={isLoading}
              tableData={roles}
              head={["Role Name", ""]}
              AuthCustomButton={AuthCustomButton}
              handleDelete={handleDeleteRoleById}
            />
          )}
        </TableContainer>
      </TabPanel>
      <TabPanel value={value} index={5}>
        <UploadFileTabPanel />
      </TabPanel>

      {openUpdateCompany && (
        <UpdateCompanyModal
          open={openUpdateCompany}
          setOpen={setOpenUpdateCompanyModal}
          setIsDoneSaving={setIsDoneSaving}
          rowData={selectedRow}
        />
      )}

      {open && (
        <AddAccountModal
          open={open}
          setOpen={setOpenAddModal}
          setIsDoneSaving={setIsDoneSaving}
          companyData={companyData}
          rowData={selectedRow}
        />
      )}
      
      {openCompany && (
        <AddCompanyModal
          open={openCompany}
          setOpen={setOpenCompanyModal}
          setIsDoneSaving={setIsDoneSaving}
          rowData={selectedCompany}
        />
      )}
      {modal && (
          <SimpleModal
            open={openSimpleModal}
            setOpen={setOpenSimpleModal}
            setIsDoneSaving={setIsDoneSaving}
            rowData={selectedCountry}
            kind={modal.kind}
            type={modal.type}
          />
      )}

      
      <Dialog open={openConfirmation} onClose={handleCancelConfirmation}>
        <DialogTitle>{"Delete Account?"}</DialogTitle>
        <DialogContent>
          <DialogContentText>Are you sure about this?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={handleCancelConfirmation}>
            Cancel
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={handleDeleteConfirmation}
            autoFocus
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default HomePage;
