import { axiosInstanceAuth } from "./setup";

export const handleGetCompanies = async (): Promise<any> => {
  const { data } = await axiosInstanceAuth().get("/admin/fetch_companies");
  return data;
};

export const handleGetUsersByCompany = async (
  company_id: number
): Promise<any> => {
  const { data } = await axiosInstanceAuth().get(
    `/admin/handle_users_by_company?company_id=${company_id}`
  );
  return data;
};

export const handleSupportSiteImport = async (
  userIdList: number[],
  file: File | undefined,
  fileName: string | undefined,
  country: string | undefined
): Promise<any> => {
  if (!file || !fileName) {
    throw new Error("file or fileName doesn't exist");
  }
  const formData: FormData = new FormData();
  formData.append("userIdList", JSON.stringify(userIdList));
  formData.append("file", file);
  formData.append("fileName", fileName);
  formData.append("country", country || "");
  const { data } = await axiosInstanceAuth().post(
    "/admin/handle_site_import_support",
    formData,
    {
      headers: { "Content-Type": "multipart/form-data" },
    }
  );
  return data;
};
