import Cookies from "js-cookie";
export const cookie = "1213123ewifhsirewurwradminsdfsfsdf24242r";

export const setCookie = (data: any) => {
  document.cookie = `${cookie}=${data}; expires=${2147483647}; path=/; Secure; SameSite=Strict`;
};

export const getCookie = () => {
  return Cookies.get(cookie) ? Cookies.get(cookie) : null;
};

export const removeCookie = () => {
  return Cookies.remove(cookie);
};
