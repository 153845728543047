import React, { ChangeEvent, useState } from "react";
import {
  Alert,
  Box,
  ButtonProps,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  Link,
  OutlinedInput,
  styled,
  TextField,
  Typography,
} from "@mui/material";
import TermsOfService from "../../components/Authentication/TermsOfService";
import { LoadingButton } from "@mui/lab";
import LoginIcon from "@mui/icons-material/Login";
import { handleLoginApi } from "../../api/auth_api";
import { setCookie } from "../../utils/cookie";
import { decodeToken } from "react-jwt";
import { setOpenForgotPasswordModal, setUser } from "../../store/userSlice";
import { useAppDispatch } from "../../store/reduxHooks";
import { useLocation, useNavigate } from "react-router-dom";
import LeftGrid from "../../components/Authentication/LeftGrid";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import FormControl from "@mui/material/FormControl";
import ForgotPasswordModal from "../../components/ForgotPasswordModal/ForgotPasswordModal";

export interface IFormData {
  email: string;
  password: string;
}

export interface IJwt {
  sub: any[];
}

const AuthCustomButton = styled(LoadingButton)<ButtonProps>(({ theme }) => ({
  borderRadius: 5,
  marginTop: 14,
  fontSize: 15,
  textTransform: "none",
  backgroundColor: "#00B196",
}));

const SignInPage = () => {
  const [formData, setFormData] = useState<IFormData>({
    email: "",
    password: "",
  });

  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = React.useState(false);
  const [error, setError] = useState("");
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleVerify = async () => {
    try {
      setIsLoading(true);
      const {
        data: { data },
      } = await handleLoginApi(formData);

      setCookie(data);
      const jwtData = decodeToken<IJwt>(data);
      dispatch(setUser(jwtData?.sub[0]));
      setIsLoading(false);
      const origin = location.state?.from?.pathname || "/home";
      // window.location.href = origin;
      navigate(origin);
    } catch (error: any) {
      if ((error instanceof Error) as any) {
        if (error.response.data) {
          setIsLoading(false);
          const errorMessage = error?.response?.data?.message || error?.response?.data?.[0]?.message;
          setError(errorMessage);
        } else {
          setIsLoading(false);
          setError("Network Error");
        }
      }
      setTimeout(() => {
        setError("");
      }, 3000);
    }
  };

  const renderView = () => (
    <form>
      <Box marginTop={5} maxWidth={400}>
        {error != "" && (
          <Box marginBottom={3}>
            <Alert severity="error">{error}</Alert>
          </Box>
        )}
        <Box>
          <TextField
            label="Email"
            id="email-signup"
            fullWidth
            type="email"
            value={formData.email}
            name="email"
            onChange={handleOnChange}
            error={false}
          />
        </Box>
        <Box marginTop={2}>
          <FormControl sx={{ width: "36.3ch" }} variant="outlined">
            <InputLabel htmlFor="outlined-adornment-password">
              Password
            </InputLabel>
            <OutlinedInput
              value={formData.password}
              id="outlined-adornment-password"
              name="password"
              onChange={handleOnChange}
              fullWidth
              error={false}
              type={showPassword ? "text" : "password"}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    sx={{ color: "#00B196" }}
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              label="Password"
            />
          </FormControl>
        </Box>
        <Box marginTop={2}>
        <Link
          onClick={() => dispatch(setOpenForgotPasswordModal())}
          sx={{ textDecoration: "none", cursor: "pointer", fontSize: 14 }}
        >
          Forgot your password?
        </Link>
      </Box>
        <AuthCustomButton
          variant="contained"
          type="submit"
          loadingPosition="end"
          loading={isLoading}
          disableRipple
          fullWidth
          disabled={
            formData.password == "" || formData.email == "" ? true : false
          }
          onClick={handleVerify}
          sx={{
            "&.MuiButtonBase-root:hover": {
              bgcolor: "#00B196",
            },
            borderRadius: "24px",
          }}
        >
          Sign In
        </AuthCustomButton>
      </Box>
    </form>
  );

  return (
    <Box>
      <Grid container>
        <LeftGrid />
        <Grid item xs={6}>
          <Box
            display="flex"
            justifyContent={"center"}
            alignItems={"center"}
            minWidth="100%"
            minHeight="100vh"
          >
            <Box>
              <Typography
                variant="h4"
                fontWeight="bold"
                sx={{
                  fontStyle: "normal",
                  fontWeight: "400",
                  fontSize: "45px",
                  lineHeight: "52px",
                  color: "#18345E",
                }}
              >
                Admin Sign In
              </Typography>
              <Typography
                sx={{
                  fontStyle: "normal",
                  fontWeight: "400",
                  fontSize: "16px",
                  lineHeight: "24px",
                  letterSpacing: "0.5px",
                  color: "#212E36",
                }}
              >
                {" "}
                Sign In to your admin account
              </Typography>
              {renderView()}
              {/* <TermsOfService /> */}
            </Box>
          </Box>
        </Grid>
      </Grid>
      <ForgotPasswordModal />
    </Box>
  );
};

export default SignInPage;
