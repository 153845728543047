import { Grid, Box } from "@mui/material";
import React from "react";
import logo from "../../images/logo.svg";

const LeftGrid = () => {
  return (
    <Grid item xs={6} sx={{ backgroundColor: "#ffffff", height: "100vh" }} className="testing">
      <Box
        display="flex"
        justifyContent={"center"}
        alignItems={"center"}
        minWidth="100%"
        minHeight="100vh"
      >
        <Box display="flex"
          justifyContent={"center"}
          alignItems={"center"}
          sx={{
            height: "98vh",
            width: "97%",
            borderRadius: "24px",
            background: "#212e36"

          }}>
          <img style={{ width: "294px" }} src={logo} />
        </Box>
      </Box>
    </Grid>
  );
};

export default LeftGrid;
