import axios, { AxiosInstance } from "axios";
import { getCookie } from "../utils/cookie";

const apiClientNoAuth = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_API,
});

apiClientNoAuth.interceptors.request.use((config: any) => {
  config.headers.transactionid = process.env.REACT_APP_TRANSACTION_ID;
  config.headers["Access-Control-Allow-Origin"] = "*";
  config.headers["Access-Control-Allow-Methods"] =
    "GET,PUT,POST,DELETE,PATCH,OPTIONS";
  return config;
});

const apiClient = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_API,
});

apiClient.interceptors.request.use((config: any) => {
  config.headers.Authorization = `Bearer ${getCookie()}`;
  config.headers.transactionid = process.env.REACT_APP_TRANSACTION_ID;
  config.headers["Access-Control-Allow-Origin"] = "*";
  config.headers["Access-Control-Allow-Methods"] =
    "GET,PUT,POST,DELETE,PATCH,OPTIONS";

  return config;
});

export const axiosInstanceAuth = (): AxiosInstance => apiClient;

export const axiosInstanceNoAuth = (): AxiosInstance => apiClientNoAuth;
