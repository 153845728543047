import { Chip } from "@mui/material";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";
import Select from "@mui/material/Select";
import * as React from "react";

interface IUserSelect {
  users: any;
  selectedUsers: string[];
  setSelectedUsers: React.Dispatch<React.SetStateAction<string[]>>;
}

const UserSelect: React.FC<IUserSelect> = ({
  users,
  selectedUsers,
  setSelectedUsers,
}): JSX.Element => {
  const handleOnChange = (event: any): void => {
    setSelectedUsers(event.target.value);
  };

  return (
    <FormControl fullWidth>
      <InputLabel id="multiple-chip-label">Users</InputLabel>
      <Select
        labelId="multiple-chip-label"
        id="multiple-chip"
        multiple
        value={selectedUsers}
        onChange={handleOnChange}
        input={<OutlinedInput id="select-multiple-chip" label="Users" />}
        renderValue={(selected: string[]) => (
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
            {selected.map((value: string) => (
              <Chip
                key={value}
                label={users[value]}
                sx={{
                  margin: "1px",
                }}
              />
            ))}
          </Box>
        )}
        disabled={!Object.keys(users).length}
        MenuProps={{
          style: {
            maxHeight: 500,
          },
        }}
        sx={{
          "&.Mui-disabled": {
            background: "#eaeaea",
            color: "#c0c0c0",
          },
        }}
      >
        {Object.keys(users).map(
          (id): JSX.Element => (
            <MenuItem key={id} value={id}>
              {users[id]}
            </MenuItem>
          )
        )}
      </Select>
    </FormControl>
  );
};

export default UserSelect;
