import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";

export const CompanyTable = ({
  isLoading,
  companyData,
  AuthCustomButton,
  handleUpdateCompanyClick,
}: any) => {
  return (
    <Table aria-label="company table" stickyHeader>
      <TableHead>
        <TableRow>
          <TableCell>Company name</TableCell>
          <TableCell align="center">Actions</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {companyData.map((row: any, index: number) => (
          <TableRow
            key={index}
            sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
          >
            <TableCell component="th" scope="row">
              {row.name}
            </TableCell>
            <TableCell align="center">
              <AuthCustomButton
                variant="contained"
                type="submit"
                loadingPosition="end"
                loading={isLoading}
                disableRipple
                onClick={() => handleUpdateCompanyClick(row)}
                sx={{
                  background: "#ffd740",
                  width: 76,
                  height: 32,
                  "&.MuiButtonBase-root:hover": {
                    bgcolor: "#ffd740",
                  },
                }}
              >
                Edit
              </AuthCustomButton>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};
