import { createTheme, SimplePaletteColorOptions } from "@mui/material";
import { ColorPartial } from "@mui/material/styles/createPalette";

declare module "@mui/material/styles" {
  interface PaletteColorOptions
    extends SimplePaletteColorOptions,
      ColorPartial {
    1?: string;
    2?: string;
    3?: string;
    4?: string;
    5?: string;
    6?: string;
    7?: string;
  }
  interface PaletteColor extends ColorPartial {
    1?: string;
    2?: string;
    3?: string;
    4?: string;
    5?: string;
    6?: string;
    7?: string;
    50: string;
  }
  interface PaletteOptions {
    neutral?: PaletteColorOptions;
    tertiary: PaletteOptions["primary"];
    destructive: PaletteOptions["primary"];
    results: PaletteOptions["primary"];
    pale: PaletteOptions["primary"];
  }
  interface Palette {
    neutral?: PaletteColor;
    tertiary: Palette["primary"];
    destructive: Palette["primary"];
    results: Palette["primary"];
    pale: Palette["primary"];
  }
}

declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    tertiary: true;
    destructive: true;
  }
}

const theme = createTheme({
  palette: {
    common: {
      black: "#212e36",
      white: "#FFF",
    },
    text: {
      primary: "#212e36",
    },
    primary: {
      main: "#212e36",
      1: "#6AC4C5",
      2: "#5BC1E7",
      3: "#1D9BD8",
      4: "#0E5073",
      5: "#092A39",
      6: "#432563",
      7: "#A92169",
    },
    secondary: {
      main: "#D82C38",
      1: "#D82C38",
      2: "#EC7531",
      3: "#F3B955",
      4: "#F6E04C",
      5: "#4CAC52",
      6: "#89C27B",
    },
    pale: {
      main: "#ECF5EA",
      1: "#ECF5EA",
      2: "#F8DEDF",
      3: "#FFE7D6",
    },
    tertiary: {
      main: "",
    },
    results: {
      main: "#ED000F", // Error // R00X
      50: "#ED000F", // Error // R00X
      100: "#BF360C", // Red100
      200: "#E65100", // Orange200
      300: "#F57F17 ", // Yellow300
      400: "#827717", // YG400
      500: "#33691E", // Green500
      600: "#00695C", // BG600
      700: "#037CA2", // Blue700
      800: "#01579B", // Indigo800
      900: "#1A237E", // Violet900
      A100: "#4527A0", // UltravioletX00
    },
    neutral: {
      main: "#1E1E1E",
      1: "#1E1E1E",
      2: "#FFFFFF",
      3: "#F3F3F3",
      4: "#E0E0E0",
      5: "#878787",
    },
    success: {
      main: "#373535",
    },
    warning: {
      main: "#EC7531",
    },
    error: {
      main: "#D82C38",
    },
    info: {
      main: "#A92169",
    },
    destructive: {
      main: "#ED000F",
    },
  },
  spacing: [
    0, 4, 8, 16, 24, 32, 40, 48, 56, 64, 72, 80, 88, 96, 104, 112, 120, 128,
    136, 144, 152, 160, 168,
  ],
  typography: {
    fontFamily: "libreFranklin_regular",
    allVariants: {
      color: "#1E1E1E",
    },
    h1: {
      fontFamily: "libreFranklin_bold",
      fontSize: "96px",
      fontWeight: 450,
      letterSpacing: "-1.5px",
      lineHeight: "112px",
    },
    h2: {
      fontFamily: "libreFranklin_bold",
      fontSize: "60px",
      fontWeight: 450,
      letterSpacing: "-0.5px",
      lineHeight: "72px",
    },
    h3: {
      fontFamily: "libreFranklin_medium",
      fontSize: "48px",
      fontWeight: 420,
      letterSpacing: "0px",
      lineHeight: "54px",
    },
    h4: {
      fontSize: "34px",
      fontWeight: 420,
      letterSpacing: "0px",
      lineHeight: "34px",
    },
    h5: {
      fontFamily: "libreFranklin_bold",
      fontSize: "24px",
      fontWeight: 420,
      letterSpacing: "0.18px",
      lineHeight: "32px",
    },
    h6: {
      fontFamily: "libreFranklin_bold",
      fontSize: "20px",
      fontWeight: 500,
      letterSpacing: "0.15px",
      lineHeight: "28px",
    },

    button: {
      fontFamily: "libreFranklin_regular",
      fontSize: "16px",
      fontWeight: 500,
      letterSpacing: "0.5px",
      lineHeight: "18px",
      textTransform: "none",
    },
  },
  components: {
    MuiButton: {
      defaultProps: {
        disableFocusRipple: true,
        disableRipple: true,
        disableTouchRipple: true,
      },
      styleOverrides: {
        root: {
          animation: "none",
          transition: "none",
          fontFamily: "libreFranklin_regular",
          fontSize: "16px",
          fontWeight: 500,
          letterSpacing: "1.25px",
          lineHeight: "18px",
          textTransform: "none",
          borderRadius: "var(--border-radius-btn)",
        },
        containedPrimary: ({ theme }) => ({
          backgroundColor: theme.palette.primary[4],
          color: theme.palette.neutral?.[2],
          padding: "10px 32px",
          position: "relative",
          borderRadius: 10,
          "&:disabled": {
            backgroundColor: theme.palette.neutral?.[5],
            color: theme.palette.neutral?.[2],
          },
          "&.Mui-focusVisible": {
            padding: "16px 28px",
            border: "4px solid transparent",
            backgroundColor: theme.palette.primary[4],
            backgroundClip: "padding-box",
            "&:before": {
              content: "''",
              position: "absolute",
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              margin: "-4px",
              borderRadius: "inherit",
              background: "var(--gradient-1)",
              zIndex: "-1",
            },
          },
        }),
        outlinedSecondary: ({ theme }) => ({
          padding: "18px 30px",
          border: "2px solid transparent",
          backgroundColor: theme.palette.neutral?.[2],
          backgroundClip: "padding-box",
          color: theme.palette.primary[5],
          "&:before": {
            content: "''",
            position: "absolute",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: "-1",
            margin: "-2px",
            borderRadius: "inherit",
            backgroundColor: theme.palette.primary[4],
          },
          "&:hover": {
            backgroundColor: theme.palette.neutral?.[2],
            border: "2px solid transparent",
            "&:before": {
              background: "var(--gradient-4)",
            },
          },
          "&:disabled": {
            backgroundColor: theme.palette.neutral?.[2],
            color: theme.palette.neutral?.[5],
            border: "2px solid transparent",
            "&:before": {
              backgroundColor: theme.palette.neutral?.[5],
            },
          },
          "&.Mui-focusVisible": {
            border: "2px solid transparent",
            outline: `2px solid ${theme.palette.primary[2]}`,
            outlineOffset: "2px",
            "&:before": {
              backgroundColor: theme.palette.primary[5],
            },
          },
        }),
      },
    },
  },
});

export default theme;
