import { Button, TextField } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useEffect, useState } from "react";
import { handleCreateNewCompany } from "../../api/accounts";

interface IAddCompanyModal {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setIsDoneSaving: React.Dispatch<React.SetStateAction<boolean>>;
  rowData?: React.SetStateAction<any>;
}

const AddCompanyModal = ({
  open,
  setOpen,
  setIsDoneSaving,
  rowData,
}: IAddCompanyModal) => {
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState("");
  const handleClose = () => {
    setOpen(false);
  };
  const handleOnChange = (e: any) => {
    const { value } = e.target;
    setFormData(value);
  };
  const handleAddCompany = async () => {
    try {
      setIsLoading(true);
      await handleCreateNewCompany(formData);
      setIsLoading(false);
      setIsDoneSaving((prev) => !prev);
      setOpen(false);
    } catch (err) {
      setIsLoading(false);
      alert("Error company might already exist");
      console.log(err);
    }
  };

  useEffect(() => {
    if (rowData) {
      setFormData(rowData.name);
    }
  }, [rowData]);
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <DialogTitle id="responsive-dialog-title">Add new companies</DialogTitle>
      <DialogContent>
        <TextField
          sx={{
            marginTop: 5,
            marginBottom: 5,
            width: 250,
          }}
          id="company_name"
          name="company_name"
          label="Company Name"
          variant="outlined"
          fullWidth
          value={formData}
          onChange={handleOnChange}
          inputProps={{ maxLength: 100 }}
        />
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          onClick={handleAddCompany}
          disabled={isLoading}
        >
          {isLoading ? "Loading..." : "Save"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddCompanyModal;
