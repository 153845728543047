import FileUploadIcon from "@mui/icons-material/FileUpload";
import { Box, Button, Typography } from "@mui/material";
import React, { useRef } from "react";

interface IFileUploader {
  setFile: React.Dispatch<React.SetStateAction<File | undefined>>;
  disabled: boolean;
  setShowUploadModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const FileUploader: React.FC<IFileUploader> = ({
  setFile,
  disabled,
  setShowUploadModal,
}): JSX.Element => {
  const ref: any = useRef(null);

  const changeFileToUpload = (file: File | undefined): void => {
    if (file) {
      setFile(file);
      setShowUploadModal(true);
    }
  };

  const handleOnDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const handleOnDrop = (event: React.DragEvent<HTMLDivElement>): void => {
    event.preventDefault();
    event.stopPropagation();
    changeFileToUpload(
      event.dataTransfer.files ? event.dataTransfer.files[0] : undefined
    );
  };

  const handleOnClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ): void => {
    event.preventDefault();
    event.stopPropagation();
    ref.current.click();
  };

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    event.preventDefault();
    changeFileToUpload(event.target.files ? event.target.files[0] : undefined);
  };

  const handleOnClickRef = (
    event: React.MouseEvent<HTMLInputElement, MouseEvent>
  ): void => {
    const element = event.target as HTMLInputElement;
    element.value = "";
  };

  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        gap: 10,
      }}
    >
      <div
        ref={ref}
        style={{
          width: "fit-content",
        }}
        onDragOver={!disabled ? handleOnDragOver : undefined}
        onDrop={!disabled ? handleOnDrop : undefined}
      >
        <Box
          sx={{
            position: "relative",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            gap: 2,
            padding: 8,
            backgroundColor: "#fff",
            borderRadius: "10px",
            border: `2px dashed ${disabled ? "#c7c7c7" : ""}`,
            overflow: "hidden",
          }}
        >
          {disabled && (
            <Box
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                height: "100%",
                width: "100%",
                backgroundColor: "#eaeaea",
                opacity: "80%",
                zIndex: 1,
              }}
            />
          )}
          <FileUploadIcon sx={{ fontSize: "50px" }} />
          <Typography
            variant="body1"
            sx={{
              textAlign: "center",
            }}
          >
            Drag a file to upload
          </Typography>
        </Box>
      </div>
      <Typography sx={{ textAlign: "center" }}>or</Typography>
      <Button
        variant="contained"
        disabled={disabled}
        sx={{
          "&.Mui-disabled": {
            background: "#eaeaea",
            color: "#c0c0c0",
          },
        }}
        onClick={!disabled ? handleOnClick : undefined}
      >
        Browse computer
      </Button>
      <input
        type="file"
        id="file"
        ref={ref}
        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
        style={{ display: "none" }}
        onChange={handleOnChange}
        onClick={handleOnClickRef}
      />
    </Box>
  );
};

export default FileUploader;
