import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { ICountry } from "../interfaces";

interface ICountrySelect {
  country: string | undefined;
  setCountry: React.Dispatch<React.SetStateAction<string>>;
}

const CountrySelect: React.FC<ICountrySelect> = ({ country, setCountry }) => {
  const countries = [
    { id: "US", name: "United States" },
    { id: "BR", name: "Brazil" },
    { id: "JP", name: "Japan" },
  ];

  return (
    <FormControl fullWidth>
      <InputLabel id="select-label">Country</InputLabel>
      <Select
        labelId="select-label"
        id="select"
        value={country}
        label="Company"
        onChange={(event: SelectChangeEvent<string>) => {
          setCountry(event.target.value);
        }}
        MenuProps={{
          style: {
            maxHeight: 500,
          },
        }}
      >
        {countries.map(
          ({ id, name }: ICountry): JSX.Element => (
            <MenuItem value={id}>{name}</MenuItem>
          )
        )}
      </Select>
    </FormControl>
  );
};

export default CountrySelect;
