import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { Box, Button } from "@mui/material";
import React from "react";

interface IDownloadTemplateButton {
  country?: string;
}

const templatesByCountry: { [key: string]: string } = {
  JP: "https://res.cloudinary.com/dksfpzy9l/raw/upload/v1712175751/Provider_Upload_Sample_Template_JP.xlsx",
  default:
    "https://res.cloudinary.com/dksfpzy9l/raw/upload/v1693322222/Provider_Upload_Sample_Template.xlsx",
};

const DownloadTemplateButton: React.FC<IDownloadTemplateButton> = ({
  country,
}): JSX.Element => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        gap: 1,
        alignItems: "center",
        justifyContent: "end",
      }}
    >
      <a
        href={templatesByCountry[country || ""] || templatesByCountry.default}
        download="Provider_Upload_Sample_Template.xlsx"
      >
        <Button variant="text" endIcon={<FileDownloadIcon />}>
          Download the file template
        </Button>
      </a>
    </Box>
  );
};

export default DownloadTemplateButton;
