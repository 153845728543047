import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { getCookie } from "../../utils/cookie";

type TProps = {
  children: React.ReactElement;
};

const ProtectedRoute: React.FC<TProps> = ({ children }) => {
  const location = useLocation();

  if (!getCookie()) {
    return <Navigate to="/" replace state={{ from: location }} />;
  }

  return children;
};

export default ProtectedRoute;
