import {
  Alert,
  AlertColor,
  Snackbar,
  SnackbarCloseReason,
} from "@mui/material";
import React from "react";

interface IToast {
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  message?: string;
  severity?: AlertColor;
}

const Toast: React.FC<IToast> = ({
  show,
  setShow,
  message,
  severity,
}): JSX.Element => {
  const handleOnClose = (
    event: Event | React.SyntheticEvent<any, Event>,
    reason?: SnackbarCloseReason
  ): void => {
    setShow(false);
  };

  return (
    <Snackbar open={show} autoHideDuration={6000} onClose={handleOnClose}>
      <Alert onClose={handleOnClose} severity={severity} sx={{ width: "100%" }}>
        {message}
      </Alert>
    </Snackbar>
  );
};

export default Toast;
