import {
  AlertColor,
  Box,
  Button,
  Fade,
  Modal,
  Typography,
} from "@mui/material";
import { AxiosError } from "axios";
import React, { useEffect, useState } from "react";
import { handleSupportSiteImport } from "../../../../api/support-file-import";
import CloseButton from "./CloseButton";
import FileDetails from "./FileDetails";
import Toast from "./Toast";

interface IUploadModal {
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  file?: File;
  setFile: React.Dispatch<React.SetStateAction<File | undefined>>;
  selectedUsers: number[];
  country?: string;
}

const UploadModal: React.FC<IUploadModal> = ({
  showModal,
  setShowModal,
  file,
  setFile,
  selectedUsers,
  country,
}): JSX.Element => {
  const [isEditingName, setIsEditingName] = useState<boolean>(false);
  const [fileName, setFileName] = useState<string>();
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [showToast, setShowToast] = useState<boolean>(false);
  const [toastProps, setToastProps] = useState<{
    message?: string;
    severity?: AlertColor;
  }>({});

  const handleOnClose = (
    event?: {},
    reason?: "backdropClick" | "escapeKeyDown"
  ): void => {
    if (reason && (reason === "backdropClick" || reason === "escapeKeyDown")) {
      return;
    }
    setFile(undefined);
    setShowModal(false);
  };

  const handleOnFileUpload = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ): Promise<void> => {
    event.preventDefault();
    setIsUploading(true);
    try {
      await handleSupportSiteImport(selectedUsers, file, fileName, country);
      setToastProps({
        message: "The file has been uploaded successfully",
        severity: "success",
      });
      handleOnClose();
    } catch (error: unknown) {
      setToastProps({
        message: (error as AxiosError<any>).response?.data.message,
        severity: "error",
      });
    }
    setIsUploading(false);
    setShowToast(true);
  };

  useEffect((): void => {
    setFileName(file?.name);
  }, [file]);

  return (
    <>
      <Modal open={showModal} onClose={handleOnClose}>
        <Fade in={showModal}>
          <Box
            sx={{
              position: "absolute" as "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 400,
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 4,
              borderRadius: "10px",
            }}
          >
            <CloseButton handleOnClose={handleOnClose} disabled={isUploading} />
            <Typography
              sx={{
                fontSize: 24,
              }}
            >
              Upload file
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignContent: "center",
                justifyContent: "center",
                mt: 2,
                gap: 2,
              }}
            >
              <FileDetails
                file={file}
                isUploading={isUploading}
                fileName={fileName}
                setFileName={setFileName}
                isEditingName={isEditingName}
                setIsEditingName={setIsEditingName}
              />
              <Button
                variant="contained"
                onClick={handleOnFileUpload}
                sx={{
                  "&.Mui-disabled": {
                    background: "#eaeaea",
                    color: "#c0c0c0",
                  },
                }}
                disabled={isEditingName || isUploading}
              >
                Upload your file
              </Button>
            </Box>
          </Box>
        </Fade>
      </Modal>
      <Toast show={showToast} setShow={setShowToast} {...toastProps} />
    </>
  );
};

export default UploadModal;
