import { IFormData } from "../pages/Authentication/SignInPage";
import { axiosInstanceNoAuth } from "./setup";

export const handleLoginApi = (formData: IFormData): Promise<any> => {
  return new Promise(async (resolve, reject) => {
    axiosInstanceNoAuth()
      .post(`/admin/login`, formData)
      .then((result) => {
        resolve(result);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
