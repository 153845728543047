import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import SignInPage from "./pages/Authentication/SignInPage";
import ErrorPage from "./pages/ErrorPage";
import { Provider } from "react-redux";
import { store } from "./store/rootStore";
import AuthContainer from "./components/AuthContainer";
import HomePage from "./pages/Home";
import ProtectedRoute from "./components/Authentication/ProtectedRoute";
import SignInContainer from "./components/SignInContainer";
import { CssBaseline, ThemeProvider } from "@mui/material";
import theme from "./utils/theme";

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <SignInContainer>
        <SignInPage />
      </SignInContainer>
    ),
    errorElement: <ErrorPage />,
  },

  {
    path: "/home",
    element: (
      <ProtectedRoute>
        <HomePage />
      </ProtectedRoute>
    ),
    errorElement: <ErrorPage />,
  }
]);

function App() {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <AuthContainer>
          <RouterProvider router={router} />
        </AuthContainer>
      </ThemeProvider>
    </Provider>
  );
}

export default App;
