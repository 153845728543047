import { Box, Container, Grid } from "@mui/material";
import { AxiosError } from "axios";
import { useEffect, useState } from "react";
import {
  handleGetCompanies,
  handleGetUsersByCompany,
} from "../../../api/support-file-import";
import CompanySelect from "./CompanySelect";
import DownloadTemplateButton from "./DownloadTemplateButton";
import FileUploader from "./FileUploader";
import UploadModal from "./UploadModal";
import UserSelect from "./UserSelect";
import { IUser } from "./interfaces";
import CountrySelect from "./CountrySelect";

interface ICompany {
  id: number;
  name: string;
}

interface IUploadFileTabPanel {}

const UploadFileTabPanel: React.FC<IUploadFileTabPanel> = ({}): JSX.Element => {
  const [companies, setCompanies] = useState<ICompany[]>();
  const [users, setUsers] = useState<IUser[]>();
  const [selectedCompany, setSelectedCompany] = useState<number>();
  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
  const [file, setFile] = useState<File | undefined>();
  const [showUploadModal, setShowUploadModal] = useState<boolean>(false);
  const [country, setCountry] = useState<string>("US");

  useEffect((): void => {
    const getCompanies = async (): Promise<any> => {
      try {
        const response: any = await handleGetCompanies();
        setCompanies(response.data);
      } catch (error: unknown) {
        console.error((error as AxiosError<any>).response?.data.message);
      }
    };

    getCompanies();
  }, []);

  useEffect((): void => {
    const getUsers = async (): Promise<any> => {
      try {
        const response: any = await handleGetUsersByCompany(selectedCompany!);
        setUsers(
          response.map(
            ({ id, first_name, last_name }: any): IUser => ({
              id,
              name: `${first_name} ${last_name}`,
            })
          )
        );
      } catch (error: unknown) {
        console.error((error as AxiosError<any>).response?.data.message);
      }
    };

    if (selectedCompany) {
      getUsers();
      setSelectedUsers([]);
    }
  }, [selectedCompany]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 4,
      }}
    >
      <DownloadTemplateButton country={country} />
      <Container maxWidth="lg">
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <CountrySelect country={country} setCountry={setCountry} />
          </Grid>
          <Grid item xs={12} md={6}>
            <CompanySelect
              companies={companies}
              selectedCompany={companies?.find(
                ({ id }: ICompany) => id === selectedCompany
              )}
              setSelectedCompany={setSelectedCompany}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <UserSelect
              users={
                users
                  ? users.reduce(
                      (acc: {}, { id, name }: IUser) => ({
                        ...acc,
                        [id]: name,
                      }),
                      {}
                    )
                  : {}
              }
              selectedUsers={selectedUsers}
              setSelectedUsers={setSelectedUsers}
            />
          </Grid>
        </Grid>
      </Container>
      <FileUploader
        setShowUploadModal={setShowUploadModal}
        setFile={setFile}
        disabled={!selectedUsers.length}
      />
      <UploadModal
        showModal={showUploadModal}
        setShowModal={setShowUploadModal}
        file={file}
        setFile={setFile}
        selectedUsers={selectedUsers.map((id: string) => Number(id))}
        country={country}
      />
    </Box>
  );
};

export default UploadFileTabPanel;
