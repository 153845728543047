import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";

export const AccountTable = ({
  AuthCustomButton,
  accountData,
  handleUpdateClick,
  handleDelete,
  isLoading,
}: any) => {
  return (
    <Table aria-label="simple table" stickyHeader>
      <TableHead>
        <TableRow>
          <TableCell>First name</TableCell>
          <TableCell align="center">Last name</TableCell>
          <TableCell align="center">Email</TableCell>
          <TableCell align="center">Company</TableCell>
          <TableCell align="center">Department</TableCell>
          <TableCell align="center">Actions</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {accountData.map((row: any, index: number) => (
          <TableRow
            key={index}
            sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
          >
            <TableCell component="th" scope="row">
              {row.first_name}
            </TableCell>
            <TableCell align="center">{row.last_name}</TableCell>
            <TableCell align="center">{row.email}</TableCell>
            <TableCell align="center">{row.name}</TableCell>
            <TableCell align="center">{row.department}</TableCell>
            <TableCell align="center">
              <AuthCustomButton
                variant="contained"
                type="submit"
                loadingPosition="end"
                loading={isLoading}
                disableRipple
                onClick={() => handleUpdateClick(row)}
                sx={{
                  background: "#ffd740",
                  width: 76,
                  height: 32,
                  "&.MuiButtonBase-root:hover": {
                    bgcolor: "#ffd740",
                  },
                }}
              >
                Edit
              </AuthCustomButton>

              <AuthCustomButton
                variant="contained"
                type="submit"
                loadingPosition="end"
                loading={isLoading}
                disableRipple
                onClick={() => handleDelete(row.id)}
                sx={{
                  background: "#f44336",
                  width: 76,
                  height: 32,
                  marginLeft: "8px",
                  "&.MuiButtonBase-root:hover": {
                    bgcolor: "#f44336",
                  },
                }}
              >
                Delete
              </AuthCustomButton>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};
