import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import EditIcon from "@mui/icons-material/Edit";
import {
  Box,
  CircularProgress,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";

interface IFileDetails {
  file?: File;
  isUploading: boolean;
  fileName?: string;
  setFileName: React.Dispatch<React.SetStateAction<string | undefined>>;
  isEditingName: boolean;
  setIsEditingName: React.Dispatch<React.SetStateAction<boolean>>;
}

const FileDetails: React.FC<IFileDetails> = ({
  file,
  isUploading,
  fileName,
  setFileName,
  isEditingName,
  setIsEditingName,
}): JSX.Element => {
  const handleOnClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ): void => {
    event.preventDefault();
    if (isEditingName && fileName?.length) {
      setIsEditingName((prev: boolean) => !prev);
    } else if (!isEditingName) {
      setIsEditingName((prev: boolean) => !prev);
    }
  };

  const handleOnChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void => {
    setFileName(event.target.value);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        gap: 1,
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      {isEditingName ? (
        <Box sx={{ width: "100%" }}>
          <TextField
            id="outlined-basic"
            label="File name"
            variant="outlined"
            value={fileName}
            onChange={handleOnChange}
            fullWidth
            error={isEditingName && !fileName?.length}
          />
          {isEditingName && !fileName?.length && (
            <span style={{ color: "#ff0000" }}>
              The file name can't be empty!
            </span>
          )}
        </Box>
      ) : (
        <Typography>{fileName}</Typography>
      )}
      {isUploading ? (
        <Box sx={{ display: "flex" }}>
          <CircularProgress size={20} />
        </Box>
      ) : (
        <>
          {isEditingName ? (
            <IconButton aria-label="rename" onClick={handleOnClick}>
              <CheckCircleIcon />
            </IconButton>
          ) : (
            <IconButton aria-label="rename" onClick={handleOnClick}>
              <EditIcon />
            </IconButton>
          )}
        </>
      )}
    </Box>
  );
};

export default FileDetails;
