import { Button, FormControl, NativeSelect, OutlinedInput, TextField } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useEffect, useState } from "react";
import { handleCreateNewEntity, handleGetCompanies, handleUpdateEntity } from "../../api/accounts";
import { ICompany } from "./UploadFileTabPanel/interfaces";
import { AxiosError } from "axios";

interface ISimpleModal {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setIsDoneSaving: React.Dispatch<React.SetStateAction<boolean>>;
  rowData?: React.SetStateAction<any>;
  kind?: string;
  type: 'country' | 'role' | 'department';
}
type FormFields = {
  name: string;
  company: number;
};

const SimpleModal = ({
  open,
  setOpen,
  setIsDoneSaving,
  rowData,
  kind,
  type,
}: ISimpleModal) => {
  const [companies, setCompanies] = useState<ICompany[]>();
  const [ companyLoading, setCompanyLoading ] = useState<boolean>(true); //eslint-disable-line
  const [selectedCompany, setSelectedCompany] = useState<number>();
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState<FormFields>();
  const handleClose = () => {
    setOpen(false);
  };
  const handleOnChange = (e: any) => {
    const { value } = e.target;
    setFormData(value);
  };
  const handleAddCountry = async () => {
    try {
      setIsLoading(true);

      switch (kind) {
        case "create": 
          await handleCreateNewEntity(
            type,
            {
            name: formData,
            company_id: selectedCompany,
          });
          break;
        case "update":
          await handleUpdateEntity(
            type,
            {
              id: rowData.id,
              name: formData,
              company_id: selectedCompany,
            }
          );
          break;
        default:
          break;
      }
      setIsLoading(false);
      setIsDoneSaving((prev) => !prev);
      setOpen(false);
    } catch (err) {
      setIsLoading(false);
      alert("Error Country might already exist");
      console.log(err);
    }
  };

  useEffect(() => {
    if (rowData) {
      setFormData(rowData.name);
      setSelectedCompany(rowData.company_id);
    }
  }, [rowData]);
  
  useEffect((): void => {
    const getCompanies = async (): Promise<any> => {
      try {
        const response: any = await handleGetCompanies();
        setCompanies(response.data.data);
        setCompanyLoading(false);
      } catch (error: unknown) {
        console.error((error as AxiosError<any>).response?.data.message);
      }
    };

    getCompanies();
  }, []);
  
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      maxWidth="lg"
    >
      <DialogTitle id="responsive-dialog-title">
        {kind === "create" ? `Add new ${type}` :  `Update ${type}`}
      </DialogTitle>
      <DialogContent>
      
        <TextField
          sx={{
            marginTop: 5,
            marginBottom: 5,
          }}
          id="name"
          name="name"
          label="Name"
          variant="outlined"
          fullWidth
          value={formData}
          onChange={handleOnChange}
          inputProps={{ maxLength: 100 }}
        />
        <FormControl>
            <NativeSelect
              sx={{
                marginBottom: 5,
                width: 250,
              }}
              inputProps={{
                name: "company_id",
                id: "uncontrolled-company_id",
              }}
              variant="outlined"
              name="company_id"
              value={selectedCompany}
              input={
                <OutlinedInput
                  sx={{ borderRadius: 2, width: 150, height: 35 }}
                />
              }
              onChange={(e) => {
                const { value } = e.target;
                setSelectedCompany(Number(value));
              }}
            >
              <option value={""}>Select a company</option>
              {companies?.map((result, index) => (
                <option value={result.id} key={index}>
                  {result.name}
                </option>
              ))}
            </NativeSelect>
        </FormControl>
        
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          onClick={handleAddCountry}
          disabled={isLoading}
        >
          {isLoading ? "Loading..." : "Save"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SimpleModal;
