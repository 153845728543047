import { LoadingButton } from "@mui/lab";
import {
  Box,
  FormControl,
  NativeSelect,
  OutlinedInput,
  TextField,
} from "@mui/material";
import { ButtonProps } from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { styled } from "@mui/material/styles";
import * as React from "react";
import { useEffect, useState } from "react";
import {
  handleCreateAccount,
  handleGetDepartments,
  handleUpdateAccount,
} from "../../api/accounts";

interface IAddAccountModal {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setIsDoneSaving: React.Dispatch<React.SetStateAction<boolean>>;
  open: boolean;
  companyData: any[];
  rowData?: React.SetStateAction<any>;
}

const AuthCustomButton = styled(LoadingButton)<ButtonProps>(({ theme }) => ({
  borderRadius: 5,
  marginTop: 14,
  fontSize: 15,
  textTransform: "none",
  backgroundColor: "#00B196",
}));

const AuthCustomButtonCancel = styled(LoadingButton)<ButtonProps>(
  ({ theme }) => ({
    borderRadius: 5,
    marginTop: 14,
    fontSize: 15,
    textTransform: "none",
    backgroundColor: "#E4A11B",
  })
);

const AddAccountModal: React.FC<IAddAccountModal> = ({
  setOpen,
  open,
  setIsDoneSaving,
  companyData,
  rowData,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const [formData, setFormData] = useState<{
    first_name: string;
    last_name: string;
    email: string;
    company_id: string | number;
    action: string;
    company_name: string;
    department: number | string;
    id?: string;
  }>({
    first_name: "",
    last_name: "",
    email: "",
    company_id: "",
    action: "create",
    company_name: "",
    department: "",
    id: "",
  });

  const [departments, setDepartments] = useState<any[]>([]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleAddAccount = async () => {
    try {
      setIsLoading(true);
      formData.company_id = parseInt(formData.company_id as string);
      delete formData.id;
      const newForm = {
        first_name: formData.first_name,
        last_name: formData.last_name,
        email: formData.email,
        company_id: formData.company_id,
        templateId: "d-8489495f685146a186f50e24215e6524",
      };
      await handleCreateAccount(newForm);
      setIsLoading(false);
      setIsDoneSaving((prev) => !prev);
      setOpen(false);
    } catch (err) {
      setIsLoading(false);
      alert("Error account might already exist");
      console.log(err);
    }
  };

  const handleEditAccount = async () => {
    try {
      setIsLoading(true);
      const companyId = parseInt(formData.company_id as string);
      const department = parseInt(formData.department as string);

      if (
        formData.first_name !== "" &&
        formData.last_name !== "" &&
        formData.company_id !== "" &&
        formData.department
      ) {
        await handleUpdateAccount(
          formData.id as string,
          formData.first_name,
          formData.last_name,
          companyId,
          department
        );
      }

      setIsLoading(false);
      setIsDoneSaving((prev) => !prev);
      setOpen(false);
    } catch (err) {
      setIsLoading(false);
      alert("Error while editing account");
      console.log(err);
    }
  };

  useEffect(() => {
    if (open && formData.company_id) {
      const fetchDepartmentsByCompany = async (companyId: number) => {
        try {
          const departments = await handleGetDepartments(companyId);
          setDepartments(departments.data.data);
        } catch (err) {
          console.log(err);
        }
      };

      fetchDepartmentsByCompany(formData.company_id as number);
    }
  }, [open, formData.company_id]);

  useEffect(() => {
    if (rowData) {
      rowData.company_id =
        companyData?.find((x) => x.name === rowData.company_name)?.id || "";
      setFormData(rowData);
    }
  }, [rowData, companyData]);

  return (
    <Dialog
      fullScreen={false}
      open={open}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">
        {formData.action === "create" ? "Create new accounts" : "Edit account"}
      </DialogTitle>
      <DialogContent>
        <Box>
          <FormControl>
            <NativeSelect
              sx={{
                marginBottom: 5,
                width: 250,
              }}
              inputProps={{
                name: "company_id",
                id: "uncontrolled-company_id",
              }}
              variant="outlined"
              name="company_id"
              value={formData.company_id}
              input={
                <OutlinedInput
                  sx={{ borderRadius: 2, width: 150, height: 35 }}
                />
              }
              onChange={(e) => {
                const { name, value } = e.target;
                setFormData((prev) => ({ ...prev, [name]: value }));
              }}
            >
              <option value={""}>Select a company</option>
              {companyData.map((result, index) => (
                <option value={result.id} key={index}>
                  {result.name}
                </option>
              ))}
            </NativeSelect>
          </FormControl>
          {formData.action === "update" ? (
            <Box sx={{ width: "100%" }}>
              <NativeSelect
                sx={{
                  marginBottom: 5,
                  width: 250,
                }}
                inputProps={{
                  name: "department",
                  id: "uncontrolled-department",
                }}
                variant="outlined"
                name="department"
                value={
                  departments.find(
                    (department) => department.name === formData.department
                  )?.id || formData.department
                }
                input={
                  <OutlinedInput
                    sx={{ borderRadius: 2, width: 150, height: 35 }}
                  />
                }
                onChange={(e) => {
                  const { name, value } = e.target;
                  setFormData((prev) => ({ ...prev, [name]: value }));
                }}
              >
                <option value={""}>Select a department</option>
                {departments.map((result, index) => (
                  <option value={result.id} key={index}>
                    {result.name}
                  </option>
                ))}
              </NativeSelect>
            </Box>
          ) : null}
          <TextField
            label="First name"
            id="first_name"
            fullWidth
            type="text"
            value={formData.first_name}
            name="first_name"
            onChange={handleOnChange}
            error={false}
            sx={{ marginBottom: 5 }}
            inputProps={{ maxLength: 130 }}
          />
          <TextField
            label="Last name"
            id="last_name"
            fullWidth
            type="text"
            value={formData.last_name}
            name="last_name"
            onChange={handleOnChange}
            error={false}
            sx={{ marginBottom: 5 }}
            inputProps={{ maxLength: 130 }}
          />
          {formData.action === "create" ? (
            <TextField
              label="Email"
              id="email-signup"
              fullWidth
              type="email"
              value={formData.email}
              name="email"
              onChange={handleOnChange}
              error={false}
              inputProps={{ maxLength: 245 }}
            />
          ) : null}
        </Box>
      </DialogContent>
      <DialogActions>
        {formData.action === "create" ? (
          <AuthCustomButton
            variant="contained"
            type="submit"
            loadingPosition="end"
            loading={isLoading}
            disableRipple
            fullWidth
            disabled={
              formData.first_name == "" ||
              formData.last_name == "" ||
              formData.email == "" ||
              formData.company_id == ""
                ? true
                : false
            }
            onClick={handleAddAccount}
            sx={{
              "&.MuiButtonBase-root:hover": {
                bgcolor: "#00B196",
              },
              borderRadius: "24px",
            }}
          >
            Add account
          </AuthCustomButton>
        ) : (
          <AuthCustomButton
            variant="contained"
            type="submit"
            loadingPosition="end"
            loading={isLoading}
            disableRipple
            fullWidth
            disabled={
              formData.first_name == "" ||
              formData.last_name == "" ||
              formData.department == "" ||
              formData.company_id == ""
                ? true
                : false
            }
            onClick={handleEditAccount}
            sx={{
              "&.MuiButtonBase-root:hover": {
                bgcolor: "#00B196",
              },
              borderRadius: "24px",
            }}
          >
            Update account
          </AuthCustomButton>
        )}

        <AuthCustomButtonCancel
          variant="contained"
          type="submit"
          loadingPosition="end"
          loading={isLoading}
          disableRipple
          fullWidth
          onClick={handleClose}
          disabled={isLoading}
          sx={{
            "&.MuiButtonBase-root:hover": {
              bgcolor: "#00B196",
            },
            borderRadius: "24px",
          }}
        >
          Cancel
        </AuthCustomButtonCancel>
      </DialogActions>
    </Dialog>
  );
};

export default AddAccountModal;
