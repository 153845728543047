import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { getCookie } from "../utils/cookie";

type TProps = {
  children: React.ReactElement;
};

const SignInContainer: React.FC<TProps> = ({ children }) => {
  const location = useLocation();

  if (getCookie()) {
    return <Navigate to="/home" replace state={{ from: location }} />;
  }

  return children;
};

export default SignInContainer;
