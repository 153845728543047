import React, { useEffect } from "react";
import { decodeToken } from "react-jwt";
import { useAppDispatch, useAppSelector } from "../store/reduxHooks";
import { setUser } from "../store/userSlice";
import { getCookie } from "../utils/cookie";

type TProps = {
  children: React.ReactElement;
};

interface IJwt {
  sub: any[];
}

const AuthContainer: React.FC<TProps> = ({ children }) => {
  const dispatch = useAppDispatch();
  const userState = useAppSelector((state) => state.userSlice);

  useEffect(() => {
    if (getCookie()) {
      const jwtData = decodeToken<IJwt>(getCookie() as string);
      dispatch(setUser(jwtData?.sub));
    }
  }, [dispatch, getCookie()]);

  return children;
};

export default AuthContainer;
