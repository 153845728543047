import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
  } from "@mui/material";
import React from "react";
  
  interface SimpleTableProps {
    isLoading: boolean;
    AuthCustomButton: React.ReactNode;
    handleUpdateClick: (param: any) => void;
    handleDelete: (param: any) => void;
    tableData: any[];
    head: string[];
  }
  export const SimpleTable = ({
    isLoading,
    AuthCustomButton,
    handleUpdateClick,
    handleDelete,
    tableData,
    head,
  }: any) => {
    return (
      <Table aria-label="company table" stickyHeader>
        <TableHead>
        <TableRow >
            {head.map((row: any, index: number) => (
                
                    <TableCell key={index}>{row}</TableCell>
                    ))}
                    </TableRow>
        </TableHead>
        <TableBody>
          {tableData.map((row: any, index: number) => (
            <TableRow
              key={index}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.name}
              </TableCell>
              <TableCell align="center">
                <AuthCustomButton
                  variant="contained"
                  type="submit"
                  loadingPosition="end"
                  loading={isLoading}
                  disableRipple
                  onClick={() => handleUpdateClick(row)}
                  sx={{
                    background: "#ffd740",
                    width: 76,
                    height: 32,
                    "&.MuiButtonBase-root:hover": {
                      bgcolor: "#ffd740",
                    },
                  }}
                >
                  Edit
                </AuthCustomButton>
                <AuthCustomButton
                variant="contained"
                type="submit"
                loadingPosition="end"
                loading={isLoading}
                disableRipple
                onClick={() => handleDelete(row.id)}
                sx={{
                  background: "#f44336",
                  width: 76,
                  height: 32,
                  marginLeft: "8px",
                  "&.MuiButtonBase-root:hover": {
                    bgcolor: "#f44336",
                  },
                }}
              >
                Delete
              </AuthCustomButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    );
  };
  