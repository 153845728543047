import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { ICompany } from "../interfaces";

interface ICompanySelect {
  companies?: ICompany[];
  selectedCompany?: ICompany;
  setSelectedCompany: React.Dispatch<React.SetStateAction<number | undefined>>;
}

const CompanySelect: React.FC<ICompanySelect> = ({
  companies,
  selectedCompany,
  setSelectedCompany,
}): JSX.Element => {
  const handleOnChange = (event: SelectChangeEvent<ICompany>): void => {
    setSelectedCompany(
      companies && companies.length
        ? companies.find(
            ({ id }: ICompany) => Number(event.target.value) === id
          )?.id
        : undefined
    );
  };

  return (
    <FormControl fullWidth>
      <InputLabel id="select-label">Company</InputLabel>
      <Select
        labelId="select-label"
        id="select"
        value={selectedCompany}
        label="Company"
        onChange={handleOnChange}
        MenuProps={{
          style: {
            maxHeight: 500,
          },
        }}
      >
        {companies &&
          companies.length &&
          companies.map(
            ({ id, name }: ICompany): JSX.Element => (
              <MenuItem value={id}>{name}</MenuItem>
            )
          )}
      </Select>
    </FormControl>
  );
};

export default CompanySelect;
