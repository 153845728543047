import { SyntheticEvent, useState } from "react";
import {
  Alert,
  Box,
  Modal,
  SnackbarOrigin,
  TextField,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useAppDispatch, useAppSelector } from "../../store/reduxHooks";
import {
  setOpenForgotPasswordModal,
  setUpdateFormConfirmCode,
  setUpdateFormEmail,
  setUpdateFormPassword,
} from "../../store/userSlice";
import {
  sendOTPForgetPasswordApi,
  verifyAndUpdatePasswordAPI,
} from "../../api/accounts";
import { validateEmail } from "../../utils/constants";

interface Props {}

export interface State extends SnackbarOrigin {
  open: boolean;
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "540px",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: 6,
  padding: "48px 48px 48px 48px",
};

const ForgotPasswordModal = ({}: Props) => {
  const openModal = useAppSelector(
    (state) => state.userSlice.forgetPassword.openModal
  );
  const dispatch = useAppDispatch();
  const formData = useAppSelector(
    (state) => state.userSlice.forgetPassword.form
  );
  const [step, setStep] = useState(0);
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");

  const handleUpdatePassword = async (e: SyntheticEvent) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      await verifyAndUpdatePasswordAPI({
        email: formData.email,
        otp: formData.confirmationCode,
        password: formData.newPassword,
      });
      setIsLoading(false);
      setMessage("Password updated");
      handleClose();
    } catch (error: any) {
      if ((error instanceof Error) as any) {
        if (error.response.data) {
          setIsLoading(false);
          if (typeof error.response.data == "string") {
            setError(error.response.data.message);
          } else {
            setError("Validation Error");
          }
        } else {
          setIsLoading(false);
          setError("Network Error");
        }
      }
      setTimeout(() => {
        setError("");
      }, 3000);
    }
  };

  const handleSendOTP = async (e: SyntheticEvent) => {
    e.preventDefault();
    try {
      if (!validateEmail(formData.email)) {
        setError("Please enter a valid email address");
        setTimeout(() => {
          setError("");
        }, 3000);
        return;
      }

      const forgetPasswordApiResponse = await sendOTPForgetPasswordApi(formData.email) as any;

      if(forgetPasswordApiResponse?.status === 200) setMessage("Code sent!");
      else setError("Something bad hapenned.");

      setTimeout(() => {
        setMessage("");
        setError("");
      }, 3000);
      setStep(1);
    } catch (error: any) {
      setError("Something bad hapenned.");

      setTimeout(() => {
        setError("");
      }, 3000);

      console.log(error);
    }
  };

  const handleClose = () => {
    dispatch(setOpenForgotPasswordModal());
  };

  const renderView = () => {
    switch (step) {
      case 0:
        return (
          <TextField
            required
            id="email"
            onChange={(e) => dispatch(setUpdateFormEmail(e.target.value))}
            label="Email"
            value={formData.email}
            inputProps={{ maxlength: 240 }}
          />
        );
      case 1:
        return (
          <Box component={"form"}>
            <Box sx={{ marginBottom: 5 }}>
              <TextField
                onChange={(e) => {
                  dispatch(setUpdateFormConfirmCode(e.target.value));
                }}
                label="Confirmation code"
                value={formData.confirmationCode}
                inputProps={{ maxlength: 150 }}
                type="text"
              />
            </Box>
            <Box>
              <TextField
                sx={{
                  "& fieldset": {
                    borderRadius: 1,
                    borderWidth: 1,
                  },
                }}
                onChange={(e) => {
                  dispatch(setUpdateFormPassword(e.target.value));
                }}
                type="password"
                label="New password"
                value={formData.newPassword}
                inputProps={{ maxlength: 150 }}
              />
            </Box>
          </Box>
        );
    }
  };

  const renderCurrentEmail = () => {
    return (
      <Box
        component="form"
        sx={{
          "& .MuiTextField-root": {
            width: 464,
          },
          "& .MuiFormLabel-asterisk": {
            display: "none",
          },
        }}
        noValidate
        autoComplete="off"
      >
        {error != "" && (
          <Box marginBottom={3}>
            <Alert severity="error">{error}</Alert>
          </Box>
        )}
        {message != "" && (
          <Box marginBottom={3}>
            <Alert>{message}</Alert>
          </Box>
        )}
        {renderView()}
      </Box>
    );
  };

  const renderButton = () => {
    return (
      <Box
        sx={{
          display: "flex",
          width: "100%",
          flexDirection: "row",
          justifyContent: "right",
        }}
      >
        {step === 0 ? (
          <LoadingButton
            sx={{ marginTop: 5 }}
            disableElevation
            loadingPosition="center"
            loading={isLoading}
            disabled={formData.email === ""}
            variant="text"
            onClick={handleSendOTP}
          >
            Next
          </LoadingButton>
        ) : (
          <LoadingButton
            sx={{ marginTop: 5 }}
            disableElevation
            loadingPosition="center"
            loading={isLoading}
            disabled={formData.email === ""}
            variant="text"
            onClick={handleUpdatePassword}
          >
            Update password
          </LoadingButton>
        )}
      </Box>
    );
  };

  return (
    <Modal open={openModal} onClose={handleClose} disableAutoFocus>
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h4" component="h2">
          Forgot password?
        </Typography>
        <Typography
          id="modal-modal-description"
          sx={{ mt: "16px", fontSize: "14px", mb: "40px" }}
        >
          To start the process, enter the email address associated with your
          account.
        </Typography>
        {renderCurrentEmail()}
        {renderButton()}
      </Box>
    </Modal>
  );
};

export default ForgotPasswordModal;
